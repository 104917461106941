@import "~bootstrap/scss/bootstrap";
*{
  font-family: 'Noto Sans JP', sans-serif;

}

body{
  background-color: #F8F8F8 !important;
}

.header{
  position: absolute;
  display: flex;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 35rem;
  background-image: url("../images/header.jpg");
  align-items: center;
  justify-content: center;

}

#logo{
  width: 16rem;
  height: 8rem;
}

#main-title{
  width: 60%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.header-p{
  color: white;
  font-size: 1.5em;
  text-align: center;
  margin-top: 3rem;

}

.flex{
  display: flex;
  justify-content: center;
}

.core{
  position: relative;
  z-index: 1;
  width: 95%;
  background-color: white;
  box-shadow: 1px 1px 12px #555;
  margin-top: 28rem;
}

h1, h2 {
  text-align: center;
}

h1{
  font-size: 1.8em;
  text-transform: uppercase;
  font-weight: 800;
}

h2{
  font-size: 2.5em;
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 3.5rem;
}

.separator{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
}

.flex-separator{
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator-p{
  text-align: center;
  font-size: 1.2em;
}

#card-explanation{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
  width: 80%;
}

#flex-card{
  display: flex;
  align-items: center;
  justify-content: center;
}

.card{
  display: flex;
  border: none !important;
}

.card p{
  margin-top: 4rem;
  text-align: center;
  text-transform: uppercase;
}

pre{
  font-size: 2% !important;
}


#title{
  display: flex;
  align-items: center;
  min-height: 15rem;

}

#map{
  border-top: 3px solid black;

}

.card-img, .card-img-top, .card-img-bottom{
  width: 6rem !important;
  height: 6rem !important;
}

.link{
  transform: rotate(0.75turn);
  width: 5rem;
  align-self: flex-start;
  margin-top: 2rem;
  margin-left: 0 !important;
}

#video{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn{
  margin-top: 3rem;

}

#footer{
  width: 100% !important;
  background-color: black;
  opacity: 0.50;
  color: white;
  text-align: center;
}

h3{
  margin-top: 3rem;
}

#legal{
  text-transform: uppercase;
}

.footer a{
  color: white;
  margin-top: 3rem;
}

#mention{
  width: 80%;
}

#core-mention{
  display: flex;
  align-items: center;
  justify-content: center;
}

h4{
  margin-top: 3rem;
  font-weight: bold;
}

#contact-flex{
  border-top: 3px solid black;
}

.btn{
  margin-bottom: 3rem !important;
}

textarea {
  resize: none;
}

.form-has-error .form-checkbox-button,
.form-has-error .form-radio-button {
  color: #d9534f;
}



.form-fieldset {
  padding: 30px;
  border: 0;
}

.form-fieldset + .form-fieldset {
  margin-top: 15px;
}

.form-legend {
  padding: 1em 0 0;
  margin: 0 0 -0.5em;
  font-size: 1.5rem;
  text-align: center;
}

.form-legend + p {
  margin-top: 1rem;
}

.form-element {
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.form-element-hint {
  font-weight: 400;
  font-size: 0.6875rem;
  color: #a6a6a6;
  display: block;
}

.form-element-bar {
  position: relative;
  height: 1px;
  background: #999;
  display: block;
}

.form-element-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  height: 2px;
  display: block;
  transform: rotateY(90deg);
  transition: transform 0.28s ease;
  will-change: transform;
}

.form-element-label {
  position: absolute;
  top: 0.75rem;
  line-height: 1.5rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  font-size: 1rem;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: #a6a6a6;
  transform: translateY(-50%);
  transform-origin: left center;
  transition: transform 0.28s ease, color 0.28s linear, opacity 0.28s linear;
  will-change: transform, color, opacity;
}

.form-element-field {
  outline: none;
  height: 1.5rem;
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border: 0 solid transparent;
  line-height: 1.5;
  width: 100%;
  color: #333;
  box-shadow: none;
  opacity: 0.001;
  transition: opacity 0.28s ease;
  will-change: opacity;
}

.form-element-field:-ms-input-placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top;
}

.form-element-field::placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top;
}

.form-element-field:focus ~ .form-element-bar::after {
  transform: rotateY(0deg);
}

.form-element-field:focus ~ .form-element-label {
  color: black;
}

.form-element-field.-hasvalue,
.form-element-field:focus {
  opacity: 1;
}

.form-element-field.-hasvalue ~ .form-element-label,
.form-element-field:focus ~ .form-element-label {
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto;
}

.form-has-error .form-element-label.form-element-label,
.form-has-error .form-element-hint {
  color: #d9534f;
}

.form-has-error .form-element-bar,
.form-has-error .form-element-bar::after {
  background: #d9534f;
}

.form-is-success .form-element-label.form-element-label,
.form-is-success .form-element-hint {
  color: #259337;
}

.form-is-success .form-element-bar::after {
  background: #259337;
}

input.form-element-field:not(:placeholder-shown),
textarea.form-element-field:not(:placeholder-shown) {
  opacity: 1;
}

input.form-element-field:not(:placeholder-shown) ~ .form-element-label,
textarea.form-element-field:not(:placeholder-shown) ~ .form-element-label {
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto;
}

textarea.form-element-field {
  height: auto;
  min-height: 3rem;
}

select.form-element-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form-select-placeholder {
  color: #a6a6a6;
  display: none;
}

.form-select .form-element-bar::before {
  content: "";
  position: absolute;
  height: 0.5em;
  width: 0.5em;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
  display: block;
  right: 0.5em;
  bottom: 0;
  transition: transform 0.28s ease;
  transform: translateY(-100%) rotateX(0deg) rotate(45deg);
  will-change: transform;
}

.form-select select:focus ~ .form-element-bar::before {
  transform: translateY(-50%) rotateX(180deg) rotate(45deg);
}

.form-element-field[type="number"] {
  -moz-appearance: textfield;
}

.form-element-field[type="number"]::-webkit-outer-spin-button,
.form-element-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-actions{
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer{
  min-height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#insta{
  width: 3vw;
}

#facebook{
  width: 3vw;
}

#linkedin{
  width: 3.5vw;
}

#youtube{
  width: 3.5vw;
}

#mention, #social{
  margin-top: 2vw;
}





